.contactMainCont {
    /* Your existing styles */
  }
  
  .container {
    width: 80%;
    margin-top: 10px;

    /* margin: 0 auto; */
  }
  
  .section {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .section-header {
    background: linear-gradient(to bottom, #9C581C, #4d290a);
    color: white;
    padding: 10px 20px;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .section-body {
    padding: 20px;
    border: 2px solid #ccc;
  }
  
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-item {
    flex: 1;
    min-width: 200px;
    padding: 10px;
    text-align: left;
  }
  
  .titles {
    font-weight: bold;
    color: #7E5530;
  }
  
  .content {
    padding-left: 20px; /* Add some space between titles and content */
  }
  
  .note {
    margin-top: 20px;
    font-style: italic;
    text-align: left;
  }
  
  #customer-complaint button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 20px 0; /* Changed from auto to 0 for left alignment */
    padding: 15px;
    background: linear-gradient(to bottom, #9C581C, #4d290a);
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; /* Keep button text centered */
  }
  
  #barrier-free-access ul {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
  }
  
  #barrier-free-access li {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 700px) {
    .container {
      width: 95%;
    }
  
    .flex-item {
      flex-basis: 100%;
    }
    #bannerImg {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      
    .content {
      padding-left: 0; /* Remove left padding on small screens */
    }
  }