/* Dashboard.css */

.dashboard {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    height: 100vw;
    width: 80vw;
    left: 10vw;
    position: relative;
    
    font-family: Arial, sans-serif;
}

.balance-info {
    text-align: center;
    padding: 20px;
}

.account-number-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: -10px;
    font-size: 1.2em;
    color: #ffffff;
}

.copy-button:hover {
    color: #000;
}

.copy-success {
    margin-left: 10px;
    color: rgb(255, 255, 255);
    font-size: 0.9em;
}

.icons-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px;
}

.dashboard-header {
    display: flex;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.greeting {
    /* flex-grow: 1; */
}
.profileSvg{
    margin-top: 3px;
}
.greeting h2 {
    font-size: 24px;
    margin: 0;
}

.greeting p {
    font-size: 16px;
    color: #666;
    margin: 5px 0 0;
}

.actions {
    display: flex;
    gap: 10px;
}

.actions button {
    padding: 10px 15px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
}

.cash-top-up {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
}

.transfer-fund {
    background-color: #8B4513;
    color: #fff;
}

.balance-info {
    background: linear-gradient(to bottom, #90521c8a, #452204);
    /* max-width: 1200px; */
    color: #fff;
    position: relative;
   

    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.balance-info h3 {
    font-size: 18px;
    margin: 0 0 10px;
}

.balance {
    font-size: 32px;
    font-weight: bold;
    margin: 0 0 10px;
}

.account-info {
    font-size: 14px;
    margin: 0 0 5px;
}

.account-number {
    font-size: 16px;
    font-weight: bold;
}

.transaction-info {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.info-box {
    flex: 1;
    background-color: #ffff;
    padding: 15px;
    border-radius: 8px;
}

.info-box h4 {
    font-size: 14px;
    color: #666;
    margin: 0 0 5px;
}

.info-box p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.recent-transactions {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.recent-transactions h3 {
    padding: 15px;
    margin: 0;
    background-color: #8B4513;
    color: #fff;
}

.recent-transactions table {
    width: 100%;
    border-collapse: collapse;
}

.recent-transactions th,
.recent-transactions td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.recent-transactions th {
    background-color: #CD853F;
    color: #fff;
}

.recent-transactions td.credit {
    color: green;
}

.recent-transactions td.debit {
    color: red;
}

.recent-transactions td.status {
    background-color: #eee;
    border-radius: 15px;
    padding: 5px 10px;
    display: inline-block;
}

@media (max-width: 768px) {
    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
        /* top: 5vw; */
        position: relative;
    }

    .balance-info {
        left: 0;

        position: relative;
        margin-top: 20px;
    }

    .icons-container {

        top: 10vw;
        margin-right: 10px;
        z-index: 2;
        position: relative;
    }

    .dashboard {
        position: relative;
        left: 0;
        height: 100%;
        width: 100%;
        top: 10vw;
    }

    .actions {
        margin-top: 10px;
    }

    .transaction-info {
        flex-direction: column;
    }

    .recent-transactions {
        overflow-x: auto;
    }
}