.deposit-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 10vw;
    background-color: #ededed;
    font-weight: bolder;
    font-size: large;
    font-family: Arial, sans-serif;
    border-radius: 20px;
  }
  @media screen and (max-width: 700px) {
    .deposit-page {
    margin-top: 30vw;
    max-width: 90vw;

    }
}
.h1 {
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select, input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .deposit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .deposit-button:hover {
    background-color: #45a049;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 500px;
    width: 100%;
  }
  
  .crypto-payment-details {
    text-align: center;
  }
  
  .bitcoin-logo {
    height: 40px;
    margin-bottom: 10px;
  }
  
  .qr-code {
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
  }
  
  .instruction {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .crypto-addresses {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .address {
    word-break: break-all;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .transaction-details {
    text-align: left;
    font-size: 14px;
  }
  
  .go-back-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .go-back-button:hover {
    background-color: #d32f2f;
  }