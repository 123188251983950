/* Sidebar.css */

.sidebar {
    width: 20vw;
    height: 100%;
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    transition: width 0.3s ease, transform 0.3s ease;
    z-index: 1000;
}

.sidebar.collapsed {
    width: 80px;
}

.sideBarLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    /* height: 0%; */
    background-color: #fd8f00;
    padding: 10px;
    border-radius: 20px;
    margin-right: 10px;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.cancel-btn {
    color: rgb(255, 255, 255);
    margin: 10px;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    background-color: green;
}

.confirm-btn {
    color: rgb(255, 255, 255);
    margin: 10px;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    background-color: rgb(255, 0, 0);
}

.side-modal-content {
    z-index: 2005;
    background-color: #ccc;
    padding: 10px;
    position: relative;
    width: 100%;
  

}

.sideBarLogoM {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 60px;
    background-color: #11100f;
    padding: 10px;
    border-radius: 20px;
    margin-right: 10px;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.sidebar-header {
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    margin: -20px -20px 20px -20px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapse-btn {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
}

.sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.sidebar-menu li {
    margin-bottom: 15px;
}

.sidebar-menu a {
    display: flex;
    align-items: center;
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.sidebar-menu a:hover,
.sidebar-menu a.active {
    background-color: #CD853F;
    color: #ffffff;
}

.sidebar-menu a svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    min-width: 20px;
}

.sidebar.collapsed .sidebar-menu a span {
    display: none;
}

.sidebar-footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.light-mode-toggle {
    display: flex;
    align-items: center;
}

.light-mode-toggle span {
    margin-right: 10px;
}

.sidebar.collapsed .light-mode-toggle span {
    display: none;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #CD853F;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.mobile-nav {
    display: none;
}

.mobile-dropdown {
    display: none;
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
    }

    .mobile-nav {
        transition: background-color 0.3s ease;
    }

    .hamburger-icon {
        transition: transform 0.3s ease;
    }

    .hamburger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 10;
    }

    .hamburger-icon:focus {
        outline: none;
    }

    .hamburger-icon span {
        width: 2rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }

    .hamburger-icon.active span:first-child {
        transform: rotate(45deg);
    }

    .hamburger-icon.active span:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
    }

    .hamburger-icon.active span:nth-child(3) {
        transform: rotate(-45deg);
    }

    .hamburger-icon.active {
        transform: rotate(90deg);
    }

    .side-modal-content {
        z-index: 1002;
        background-color: #ccc;
        padding: 10px;
        width: 100vw;
        top: 20vw;
        left: 0vw;

    }

    .mobile-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000000;
        color: #ffffff;
        padding: 40px;
        position: fixed;
        /* width: 100vw; */
        height: 20vw;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1001;
        border-bottom: 10px solid rgb(146, 91, 32);
    }

    .hamburger-icon {
        font-size: 24px;
        background: none;
        border: none;
        color: #ffffff;
        cursor: pointer;
    }

    /* .mobile-dropdown {
        display: none;
        position: absolute;
        top: 19vw;
        left: 0;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        list-style: none;
        padding: 10px 0;
    }

    .mobile-dropdown.active {
        display: block;
    }

    .mobile-dropdown a {
        color: #333333;
        text-decoration: none;
        padding: 10px 20px;
        display: block;
    }

    .mobile-dropdown a:hover,
    .mobile-dropdown a.active {
        background-color: #CD853F;
        color: #ffffff;
    } */

    .mobile-dropdown {
        display: block;
        position: fixed;
        top: 20vw;
        left: 0;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        list-style: none;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
        opacity: 0;
    }

    .mobile-dropdown.active {
        max-height: 500px;
        /* Adjust this value based on your content */
        opacity: 1;
    }

    .mobile-dropdown a {
        color: #333333;
        text-decoration: none;
        padding: 15px 20px;
        display: block;
        transform: translateY(-20px);
        opacity: 0;
        transition: transform 0.3s ease-out, opacity 0.3s ease-out, background-color 0.3s ease;
    }

    .mobile-dropdown.active a {
        transform: translateY(0);
        opacity: 1;
    }

    .mobile-dropdown a:nth-child(1) {
        transition-delay: 0.1s;
    }

    .mobile-dropdown a:nth-child(2) {
        transition-delay: 0.2s;
    }

    .mobile-dropdown a:nth-child(3) {
        transition-delay: 0.3s;
    }

    /* Add more if you have more items */

    .mobile-dropdown a:hover,
    .mobile-dropdown a.active {
        background-color: #CD853F;
        color: #ffffff;
    }

    @keyframes slideDown {
        from {
            transform: translateY(-20px);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}