.settings-container {
    max-width: 800px;
    margin: 2rem auto;
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .settings-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .settings-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .profile-pic-container {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #f0f0f0;
    transition: all 0.3s ease;
  }
  
  .profile-pic:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .change-pic-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .change-pic-button:hover {
    background-color: #357abd;
  }
  
  .file-input-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-input {
    margin-bottom: 0.5rem;
  }
  
  .cancel-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #c0392b;
  }
  
  .settings-form {
    width: 100%;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #555;
  }
  
  .form-input,
  .ant-picker {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus,
  .ant-picker:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  .security-section {
    margin-bottom: 2rem;
  }
  
  .security-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .security-button {
    background-color: transparent;
    color: #4a90e2;
    border: 2px solid #4a90e2;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .security-button:hover {
    background-color: #4a90e2;
    color: white;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .save-button {
    background: linear-gradient(to right, #4a90e2, #357abd);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .save-button:hover {
    background: linear-gradient(to right, #357abd, #2980b9);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .success-alert {
    background-color: #2ecc71;
    color: white;
    text-align: center;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .settings-container {
      padding: 1.5rem;
    }
  
    .form-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .change-password-section {
    margin: 20px 0;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 400px;
  }
  
  .change-password-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .change-password-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #dc3545;
    margin: 10px 0;
    padding: 10px;
    background-color: #f8d7da;
    border-radius: 4px;
  }
  
  .success-message {
    color: #28a745;
    margin: 10px 0;
    padding: 10px;
    background-color: #d4edda;
    border-radius: 4px;
  }