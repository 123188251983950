/* .sidebar {
    display: none;
} */


@media screen and (max-width: 700px) {
    .rpmHeader{
        background: linear-gradient(to right, #A05618, #E2A04E, #A45B1C);
        color: white;
        height: 50px;
        width: 83% !important;
    }
    .safeContent{
        margin-left: 25px;
    }
    .rpmBody{
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 280px; /* Adjust as needed */
        width: 83% !important;
    }
    .rpmBodyFlex{
        display: flex;
    }
    .rpmBody div {
        margin-right: 50px; /* Optional: Add spacing between the two divs */
    }
    .safeContenti {
        margin-left: 15%;
    }
    #rpmtitle{
        color: #A05618;
        margin-left: 20px;
        font-size: large;
        font-weight: bold;
    }
    .safeContent {
        justify-content: center;
        align-items: flex-start;
    }

    .safeContent div {
        border: 2px solid rgb(255, 255, 255);
        width: 100%;
        text-align: left;
    }
    #ash{
        background-color: #EDE8D5;
    }
    .safeContentHeader {
        background: linear-gradient(to right, #A05618, #E2A04E, #A45B1C);
        color: white;
        height: 50px;
        width: 92% !important;
    }
    .safeContentHeader h4{
        margin-left: 20px;
    }
    .safeContentBody {
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 100px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px; /* Adjust as needed */
    }
    .safeContentBodyi{
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 760px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px;
    }
    .safeContentBodyj{
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 160px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px;
    }
}

@media screen and (min-width: 700px) {
    .safeContent {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .safeContent div {
        border: 2px solid rgb(255, 255, 255);
        width: 42%;
        text-align: left;
    }
    .safeContentHeader {
        background: linear-gradient(to right, #A05618, #E2A04E, #A45B1C);
        color: white;
        height: 50px;
        width: 92% !important;
    }
    .safeContentHeader h4{
        margin-left: 20px;
    }
    .safeContentBody {
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 100px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px; /* Adjust as needed */
    }
    .safeContentBodyi{
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 760px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px;
    }
    .safeContentBodyj{
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 160px; /* Adjust as needed */
        width: 92% !important; /* Adjust as needed */
        margin-top: 10px;
    }
    .safeContent span{
        margin-left: 50px;
    }
    #largeSpan{
        font-size: 18px;
    }
    .sdbdheader{
        margin-top: 20px;
        height: 45px;
        width: 80% !important;
        background-color: #A05618;
        color: white;
        margin-left: 50px;
    }
    .sdbdheader span{
        font-size: 20px;
    }
    .sdbdBody{
        margin-left: 50px;
        width: 80% !important;
    }
    
    .sdbdBody p{
        font-size: larger;
        margin-left: 70px;
    }
    #sdbdSpan{
        margin-left: 130px;
    }
    #ash{
        background-color: #EDE8D5;
    }
    .rpmHeader{
        background: linear-gradient(to right, #A05618, #E2A04E, #A45B1C);
        color: white;
        height: 50px;
        width: 83% !important;
    }
    .rpmBody{
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 180px; /* Adjust as needed */
        width: 83% !important;
    }
    .rpmBodyFlex{
        display: flex;
    }
    .rpmBody div {
        margin-right: 50px; /* Optional: Add spacing between the two divs */
    }
    .safeContenti {
        margin-left: 15%;
    }
    #rpmtitle{
        color: #A05618;
        margin-left: 20px;
        font-size: large;
        font-weight: bold;
    }
    
}
