.footer {
  background: linear-gradient(to right, #f5a002, #faa22e);

/* background-color: #bc7604; */
color: #ffc60c;
padding: 20px 0;
position: relative;
height: 10.
vw;
overflow: hidden;
  color: #000000;
  padding: 1rem 0;
  font-size: 0.875rem;
}

.footer-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  min-width: 200px;
}

.footer-logo {
  height: 36px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.footer-copyright {
  font-size: 0.75rem;
}

.footer-heading {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 1rem;
  }
}