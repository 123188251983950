.notification-dropdown {
  position: relative;
  display: inline-block;
}

.notification-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4d4f;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  display: none; /* Hide by default */
}

.notification-dropdown:hover .dropdown-content,
.notification-dropdown.open .dropdown-content {
  display: block; /* Show on hover or when dropdown is open */
}

.dropdown-content h3 {
  padding: 12px 16px;
  margin: 0;
  border-bottom: 1px solid #ddd;
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
}

.dropdown-content li:last-child {
  border-bottom: none;
}

.dropdown-content li.unread {
  background-color: #e6f7ff;
}

.dropdown-content li p {
  margin: 0 0 5px 0;
}

.dropdown-content li small {
  color: #888;
}

.dropdown-content li button {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
}

.dropdown-content li button:hover {
  background-color: #40a9ff;
}
