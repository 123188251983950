.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20vw;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .reset-password-container button {
    padding: 10px;
    background-color: #CD7F32;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .reset-password-container button:hover {
    background-color: #B8860B;
  }
  

  .password-requirements ul {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    padding-left: 10px;
    border-left: 3px solid #ccc;
  }
  
  
  /* .password-requirements p:first-child {
    font-weight: bold;
    margin-bottom: 5px;
  } */
  
  .password-requirements ul {
    padding-left: 20px;
    margin-top: 5px;
  }
  
  .password-requirements li {
    margin-bottom: 5px;
  }