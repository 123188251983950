
.view-user-cont {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-actions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .user-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .user-actions button:hover {
    background: #0056b3;
  }
  
  .user-details, .transactions {
    margin-bottom: 20px;
  }
  
  .user-details p {
    margin: 5px 0;
  }
  
  .transaction-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transaction-table th, .transaction-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* .transaction-table th {
    background: #f2f2f2;
  } */
  
  .back-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: #28a745;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .back-button:hover {
    background: #218838;
  }
  
  /* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
   */
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .modal-actions button:first-child {
    background: #dc3545;
    color: white;
  }
  
  .modal-actions button:first-child:hover {
    background: #c82333;
  }
  
  .modal-actions button:last-child {
    background: #6c757d;
    color: white;
  } 
 
  .modal-actions button:last-child:hover {
    background: #5a6268;
  }
  
/* Example styles for ViewUser component */
.view-user-cont {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.view-user-cont h1{
    color: #fd8f00;
}

.user-details {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.transaction-table th, .transaction-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.back-button {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #fd8f00;
    color: white;
    font-style: italic;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #a55d00;
}
