/* Container styles */
.pwd-reset-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 20px;
  }
  
  .pwd-reset-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    padding: 24px;
  }
  
  .pwd-reset-header {
    margin-bottom: 24px;
  }
  
  .pwd-reset-title {
    font-size: 24px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
  }
  
  /* Form styles */
  .pwd-reset-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .pwd-reset-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .pwd-reset-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.2s ease;
  }
  
  .pwd-reset-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }
  
  .pwd-reset-input::placeholder {
    color: #9ca3af;
  }
  
  /* Button styles */
  .pwd-reset-submit-btn {
    width: 100%;
    padding: 10px 16px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .pwd-reset-submit-btn:hover {
    background-color: #1d4ed8;
  }
  
  .pwd-reset-submit-btn:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
  
  /* Alert styles */
  .pwd-reset-alert {
    padding: 12px 16px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 14px;
  }
  
  .pwd-reset-alert-success {
    background-color: #dcfce7;
    color: #166534;
    border: 1px solid #86efac;
  }
  
  .pwd-reset-alert-error {
    background-color: #fee2e2;
    color: #991b1b;
    border: 1px solid #fecaca;
  }
  
  /* Link styles */
  .pwd-reset-back-link {
    display: block;
    text-align: center;
    color: #2563eb;
    text-decoration: none;
    font-size: 14px;
    margin-top: 16px;
  }
  
  .pwd-reset-back-link:hover {
    text-decoration: underline;
    color: #1d4ed8;
  }
  
  /* Loading spinner */
  .pwd-reset-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: pwd-reset-spin 1s linear infinite;
    margin-right: 8px;
  }
  
  @keyframes pwd-reset-spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .pwd-reset-card {
      padding: 16px;
    }
  
    .pwd-reset-title {
      font-size: 20px;
    }
  }