
.edit-user-cont {
    width: 80vw;
  
    padding: 20px;
    position: relative;
    left: 20vw;
    background-color: #c2c2c2;
    border-radius: 10px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #fff;
}

.edit-user-form .form-group {
    margin-bottom: 15px;
}

.edit-user-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}
.edit-user-form input {
    width: 40%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block;
    margin: 0 auto; /* Centers the input horizontally */
}


.transactions {
    margin-top: 20px;
}

.transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.transaction-table th, .transaction-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.transaction-table th {
    background-color: #333;
    color: #fff;
}

.transaction-table td input {
    width: calc(100% - 20px);
    padding: 8px;
    border: none;
    border-radius: 5px;
}

.add-transaction-button, .delete-button, .save-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-transaction-button:hover, .delete-button:hover, .save-button:hover {
    background-color: #555;
}

/* Edit User Form Styles */

.edit-user-cont {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.edit-user-cont h1 {
    color: #007bff;
    margin-bottom: 1.5rem;
    text-align: center;
}

.edit-user-form {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="number"],
.form-group input[type="date"],
.form-group select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus,
.form-group input[type="number"]:focus,
.form-group input[type="date"]:focus,
.form-group select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-group input[type="checkbox"] {
    margin-right: 0.5rem;
}

.save-button {
    grid-column: 1 / -1;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.save-button:hover {
    background-color: #0056b3;
}

/* Styles for dropdown menus */
.form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23495057' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 12px;
    padding-right: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .edit-user-cont {
        padding: 1rem;
    }

    .edit-user-form {
        grid-template-columns: 1fr;
    }
}