.form-container {
    max-width: 500px;
    margin: 0 auto;
    background: white;
    padding: 20px;
  
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  @media (max-width: 768px) {
  
   .form-container {
      width: 100%;
      position: relative;
      top: 30vw;
   }
  }
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-header h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .close-icon {
    cursor: pointer;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background: linear-gradient(to bottom, #90521c8a, #452204);

    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #FF6347;
  }
  
  .back-button {
    margin-top: 20px;
    color: #666;
    cursor: pointer;
    text-decoration: underline;
    background: none;
    border: none;
    font-size: 16px;
  }
  
  .popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #7a3f0e;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 0 0;
  }
  
  .popup-message {
    font-weight: bold;
  }
  
  .popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  